<template>
  <div>
    <!-- 报名界面 -->
    <div class="header-box" />
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped>
.header-box {
  height: 60px;
}
</style>
